<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="bi bi-diagram-3 display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Merchant account list and account creation</small>
                </div>
              </div>
              <div class="ms-auto">
                <button class="btn btn-outline-info fw-medium me-1" @click="resetResellerPage()" v-if="selectedReseller.account_db_code !=='all' && selectedReseller.account_db_code !=='-1' && selectedReseller.account_db_code !==currentAccount.account_db_code"> 
                  <i class="uil uil-home-alt font-size-16"></i>
                </button>
                <button class="btn btn-outline-info fw-medium me-1" @click="goBack()" v-if="selectedReseller.account_db_code !=='all' && selectedReseller.account_db_code !=='-1' && selectedReseller.account_db_code !==currentAccount.account_db_code" :disabled="disabledGoBack">
                  <i class="mdi mdi-arrow-left font-size-16"></i> 
                </button>
                <button class="btn btn-outline-info fw-medium" v-if="!(reseller.length==1 &&  selectedReseller.account_db_code=='all') && create_modify_permission" @click="createMerchant">
                  <i class="uil uil-plus font-size-16 me-1"></i> Add Merchant Account
                </button>
              </div>
            </div>
          </div>
     
          <div class="card-body">
            <div class="rounded-4 p-3 p-lg-4 mb-4 custom_top_data_bar">
              
                <div class="text-center" v-if="loading">
                <div
                  class="spinner-border text-secondary my-5"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div v-else>
                <h4 class="font-size-18 text-white mb-2"> Account Info</h4>
                <div class="row mb-2">
                  <div class="col-12 col-lg-4">
                    <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="changeUrl()">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                            {{ props.option.account_name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                            
                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} 
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
                  </div>
                </div>
                <div class="row" v-if="selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'">
                  <div class="col-12 col-lg col-md-6 mb-3 mb-lg-0" >
                     <!-- mobile -->
                     <div class="d-flex align-items-center">
                        <div><i class="uil uil-user me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Account</div>
                          <div class="text-purple">
                        #{{selectedReseller.id}} {{selectedReseller.account_name}} 
                      </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-6 mb-3 mb-lg-0">
                     <!-- mobile -->
                     <div class="d-flex align-items-center">
                        <div><i class="uil uil-sitemap me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Reseller Upline</div>
                          <div class="text-purple mb-0 font-size-14">
                            <span v-if="selectedReseller.account_username !==defaultName">
                              <span v-if="account_type=='reseller' &&  selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name==defaultName "> {{ selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name }}</span>
                              <span v-else @click="goBack()" class="cursor-pointer"> {{ selectedReseller.reseller_parent[selectedReseller.reseller_parent.length - 1].name }}</span>
                            </span>
                            <span v-else>
                              -
                            </span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-6 mb-3 mb-lg-0">
                   
                       <!-- mobile -->
                      <div class="d-flex align-items-center cursor-pointer" @click="showContract(selectedReseller,'reseller')">
                        <div><i class="uil uil-usd-circle  me-2 text-primary display-6"></i> </div>
                        <div> 
                          <div class="font-size-16 fw-medium mb-0 lh-1">Contracts</div>
                          <a class="text-purple d-block font-size-14 lh-1" href="javascript:void(0)">View Contracts <i class="uil uil-arrow-right font-size-16"></i>  </a>
                        </div>
                      </div>
                  </div>
                  <div class="col-12 col-lg col-md-6">
                    <!-- mobile -->
                    <div class="d-flex align-items-center cursor-pointer" @click="showBalance(selectedReseller,'reseller')">
                      <div><i class="uil uil-wallet me-2 text-primary display-6"></i> </div>
                      <div> 
                        <div class="font-size-16 fw-medium mb-0 lh-1">Current Balance</div>
                        <a class="text-purple d-block font-size-14 lh-1" href="javascript:void(0)">View Balance <i class="uil uil-arrow-right font-size-16"></i>  </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
         
            <h4 class="font-size-18 mb-3"><i class="uil uil-list-ul me-1 text-primary"></i> 
              <span v-if="selectedReseller.account_db_code !=='all'">{{ selectedReseller.account_name }} - </span>
              <span v-else>All </span>
              Merchants List ({{ totalData }})</h4>

              <div class="col-12 col-md-6 mb-2">
                <div class="d-flex mb-3">
                <div class="app-search2 position-relative col">
                  <input
                    type="text"
                    placeholder="Search Merchant/Financial Group"
                    class="form-control mb-0"
                    v-model.trim="searchMerchant"
                    autocomplete="no"
                    @keydown.enter="handlePageChange(1)"
                    @search="handlePageChange(1)"
                  />
                  <span class="uil-search text-primary"></span>
                </div>
                <button
                  class="btn btn-light rounded ms-1"
                  type="button"
                  :disabled="disabled"
                  @click="searchMerchant='', handlePageChange(1)"
                >
                  <span>Clear</span>
                </button>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760"  :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Merc. Code</th>
                    <th>Merc. Name</th>
                    <th>Reseller</th>
                    <th>Balance</th>
                    <th v-if="baseCurrency=='HKD'">Frozen Balance</th>
                    <th>Financial</th>
                    <th>Payin Rate</th>
                    <th>E-Wallet (UPI) Rate</th>
                    <th>Payout Rate</th>
                    <th>Status</th>
                    <th>API Connect</th>
                    <th>Created Date</th>
                    <th class="text-center" v-if="(currentRouteName=='merchants' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td :colspan="(currentRouteName == 'merchants' || account_type=='admin' || $route.params.id == currentAccount.account_db_code ) && create_modify_permission ? 11 : 10" height="300" class="text-center text-muted">
                      <i class="uil uil-user me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td :colspan="(currentRouteName == 'merchants' || account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission || baseCurrency=='HKD' ? 11 : 10" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
                  <tr v-for="(value, index) in returnData" :key="index" >
                    <td>{{ value.merchant_code }}</td>
                    <td class="text-primary fw-medium">{{ value.account_name }}</td> 
                    <td> {{ value.reseller_parent[value.reseller_parent.length - 1].name}}</td>
                    <td>
                     
                      
                      <a href="javascript:void(0)" v-if="create_modify_permission && account_type=='admin' " v-b-tooltip.hover title="" class="btn btn-outline-primary btn-sm p-1 lh-1 font-size-11 me-1 fw-medium"  @click="updateMerchantBalance(value)" >
                        Update
                      </a>
                      <!--  @click="showBalance(value,'merchant')" -->
                      <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-primary font-size-14">
                        <span v-if="value.contract_balance"> {{ convertCurrencyFormat(value.contract_balance,true) }}
                          <i class="bi bi-snow2 font-size-13"  v-if="baseCurrency=='HKD' && value.contract_frozen>0" v-b-tooltip.hover :title="''+ convertCurrencyFormat(value.contract_balance-value.contract_frozen,true)"  :class="{
                            'text-danger':((value.contract_balance-value.contract_frozen)<0),
                            'text-muted':((value.contract_balance-value.contract_frozen)>0)
                          }" ></i>
                        </span>
                        <span v-else>0.00</span>
                      </a>
                      
                    </td>
                    <td v-if="baseCurrency=='HKD'">
                     <a href="javascript:void(0)" v-if="create_modify_permission && account_type=='admin' " v-b-tooltip.hover title="" class="btn btn-outline-danger btn-sm p-1 lh-1 font-size-11 me-1 fw-medium"  @click="updateMerchantFreezeBalance(value)" >
                       Update
                     </a>
                     <a href="javascript:void(0)" v-b-tooltip.hover title="" class="text-danger font-size-14">
                       <span v-if="value.contract_frozen"> {{ convertCurrencyFormat(value.contract_frozen,true) }}</span>
                       <span v-else>0.00</span>
                     </a>
                     
                   </td>
                    <td>
                      <span v-if="value.financial_group"><i class="bi bi-collection font-size-13 me-1 text-primary"></i>  {{ value.financial_group.name }}</span>
                      <span v-else> <i class="bi bi-collection text-primary font-size-13 me-1"></i> -</span>
                    </td>
                    
                   
                    <td>
                      {{ value.payin_rate }}% + 
                      <span v-if="value"> {{ convertCurrencyFormat(value.payin_extra_charges,true) }}</span>
                    </td>
                    <td>
                      {{ value.ewallet_rate }}% + 
                      <span v-if="value"> {{ convertCurrencyFormat(value.ewallet_extra_charges,true) }}</span>
                    </td>
                    <td>
                      {{ value.payout_rate }}% + 
                      <span v-if="value"> {{ convertCurrencyFormat(value.payout_extra_charges,true) }}</span>
                    </td>
                    <td>
                      <div v-if="(currentRouteName=='merchants' &&  account_type=='admin' || $route.params.id == currentAccount.account_db_code) && create_modify_permission">
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="value.status" true-value="active" false-value="inactive" :disabled="loading || modalLoading" @change="updateMerchantStatus(value)">
                        </div>
                      </div>
                      <div v-else>
                        <span v-if="value.status=='inactive'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                        <span v-else-if="value.status=='active'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; {{value.status}}</span>
                      </div>
                    </td>
                    
                    <td>
                      <span v-if="value.api_enabled=='0'" class="text-danger font-size-14 me-1 text-capitalize text-uppercase">&#9679; Disabled</span>
                      <span v-else-if="value.api_enabled=='1'" class="text-success font-size-14 me-1 text-capitalize text-uppercase">&#9679; Connected</span>
                    </td> 
                    <td>
                      {{ value.created_at }}
                    </td>
                    <td class="text-center" v-if="(currentRouteName=='merchants'|| account_type=='admin' || $route.params.id == currentAccount.account_db_code)&& create_modify_permission">
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded me-2" @click="createMerchantPayin(value)" v-if="account_type=='admin'">
                      <i class="bi bi-coin"></i></button>
                      <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="editMerchant(value)">
                      <i class="uil uil-edit"></i></button>
                    </td>  
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length && !loading">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <merchants ref="modalFunc" :data="{'accessUsername':accessUsername, 'accessToken':accessToken, 'updateData':updateData}" @callParentFunction="go2theResellerPageAfterAdd" @callParentFunction2="handlePageChange(currentPage)" />
    <reseller ref="modalFunc2" :data="{'accessUsername':accessUsername, 'accessToken':accessToken, 'updateData':updateData}" @callParentFunction="handlePageChange(currentPage)" />
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import merchants from "@/views/pages/app/modal/createEditMerchant";
import reseller from "@/views/pages/app/modal/createEditReseller";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

const CancelToken = axios.CancelToken;
let cancel;

/**
 * Merchants page
 */
const PageTitle = "Merchants"
const ResellerDefault =
{
  "account_db_code": "-1",
  "account_name": "Current Account",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
const AllAccountDefault =
{
  "id": "",
  "account_username": "",
  "account_db_code": "all",
  "account_name": "All Accounts",
  "account_type": "",
  "up_reseller_count": 0,
  "reseller_parent": [],
  "reseller_level": "",
  "reseller_code": "",
  "merchant_count": 0
}
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    Multiselect,
    merchants,
    reseller
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      baseCurrency: appConfig.baseCurrency,
      defaultName: appConfig.defaultAccountName,
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      account_type:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      prevRoute:{
        name: "",
      },
      updateData:{},
      perPage: 20,
      totalData: 0,
      searchMerchant:"",
      returnData: [],
      submitted:false,
      loading:false,
      modalLoading:false,
      disable_date:false,
      disabled:false,
      buttonLoading:[false,false,false],
      currentRouteName: "",
      selectedReseller: ResellerDefault,
      reseller:[ResellerDefault],
      disabledGoBack:false,
      currentAccount:{ "account_db_code": ""},
      supportBackPage:['payin','payout','resellers'],
      create_modify_permission:false
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
    })
  },
  watch:{
    $route (to, from){
        console.log(to)
        console.log(from)
      }
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    }
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.getReseller()
    this.currentRouteName = this.$route.name
  }, 
  created(){
   console.log('')
  },
  methods:{
    updateMerchantStatus(value){
        this.$Progress.start();
        this.modalLoading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("merchantId", value.account_db_code);
        bodyFormData.append("status", value.status);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchantStatus',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            this.returnData = []
            if (resData.status == 200) {
              console.log(resData)
              this.handlePageChange(this.currentPage)
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
    },
    go2theResellerPageAfterAdd(value){
      var currentID = value;
      this.reseller.forEach(element => {
          if (element.account_db_code==currentID){
            console.log(element)
            this.selectedReseller = element
          }
      });
      if (this.$route.params.id !== currentID){
        this.changeUrl();
      }else{
        this.handlePageChange(this.currentPage)
      }
    },
    resetResellerPage(){
      this.selectedReseller = (this.account_type==='admin') ? AllAccountDefault : this.currentAccount;
      this.changeUrl();
    },
    goBack(){
      if (this.supportBackPage.includes(this.prevRoute.name)) {
        this.$router.push({ name: this.prevRoute.name }).catch(() => {});
      }else{
        var parentId = this.selectedReseller.reseller_parent[this.selectedReseller.reseller_parent.length - 1].account_db_code;
        this.reseller.forEach(element => {
            if (element.account_db_code==parentId){
              console.log(element)
              this.selectedReseller = element
            }
        });
        this.disabledGoBack =true
        if (this.$route.params.id !==this.selectedReseller.account_db_code){
          this.changeUrl();
          this.disabledGoBack = false
        }
      }
      
      // if (parentId==this.selectedReseller.account_db_code){
      //   this.disabledGoBack = true;
      // }else{
      //   this.disabledGoBack = false;
      // }
      
    },
    createMerchant(){
      setTimeout(() =>  this.$refs.modalFunc.showModal(),100)
    },
    editMerchant(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showEditModal(),100)
    },
    createMerchantPayin(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showMerchantCreatePayinModal(),100)
    },
    updateMerchantBalance(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showMerchantUpdateBalanceModal(),100)
    },
    updateMerchantFreezeBalance(value){
      this.updateData=value;
      setTimeout(() =>  this.$refs.modalFunc.showMerchantUpdateFreezeBalanceModal(),100)
    },
    showContract(value,type){
      this.updateData=value;
      this.updateData.current_account_db_code=this.currentAccount.account_db_code;
      if (type=='reseller'){
        setTimeout(() =>  this.$refs.modalFunc2.showViewModal('contract'),100)
      }else if(type=='merchant'){
        setTimeout(() =>  this.$refs.modalFunc.showViewModal('contract'),100)
      }
    },
    showBalance(value,type){
      this.updateData=value;
      if (type=='reseller'){
        setTimeout(() =>  this.$refs.modalFunc2.showViewModal('wallet'),100)
      }else if(type=='merchant'){
        setTimeout(() =>  this.$refs.modalFunc.showViewModal('wallet'),100)
      }
     
    },

    changeUrl(){
      console.log('changeUrl')
      this.disabledGoBack=false;
      if(this.selectedReseller.account_db_code==-1 || this.selectedReseller.account_db_code=='all'){
        this.$router.replace({ name: 'merchants' })
        this.currentRouteName = 'merchants'
        this.handlePageChange(1)
      }else{
        this.$router.replace({ name: 'merchants with reseller id', params:{id:this.selectedReseller.account_db_code}})
          this.currentRouteName = 'merchants with reseller id'
          this.handlePageChange(1)
      }
    },
    convertCurrencyFormat(value,show00) {
      if (show00==true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      }else{
          // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
      }
    },
    getReseller() {
      if(cancel !== undefined) cancel();
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(c => cancel = c)
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = [ResellerDefault]
          this.selectedReseller = ResellerDefault
          if (this.account_type=='admin'){
            this.reseller=[AllAccountDefault]
            this.selectedReseller = this.reseller[0]
          }
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
            this.currentAccount = resData.current_account
          }
          resData.data.forEach((element) => {
            //remove z because z don't have merchant account one
            if (element.account_username!==this.defaultName){
              this.reseller.push(element);
            }
            if (element.account_db_code == this.$route.params.id){
              this.selectedReseller = element
            }
          });
          this.handlePageChange(1)

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
        }

        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum);
        //this.exportData(1)
    },
    async getData(pages){
        if(cancel !== undefined) cancel();
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code );
        bodyFormData.append("searchMerchant", this.searchMerchant);
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/admin/getAllMerchantListV2',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
          cancelToken: new CancelToken(c => cancel = c)
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            if (resData.status == 200) {
              this.totalData = resData.total;
              var responseData = resData.data;

              this.returnData = responseData;
              if (this.selectedReseller.account_db_code==-1){
                //if user type wrongly no match with reseller list xxx/merchants/from-reseller=abc123
                if (this.$route.name!=='merchants'){
                  //this.$router.replace({ name: 'merchants' })
                  this.currentRouteName = 'merchants'
                }
              }
             
               
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          const permission = data.modules.filter(e => e.name==='account_management')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
            console.log(this.create_modify_permission)
          } else {
            console.log('Module with name "account_management" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>