<template>
  <div>
     <b-modal @hidden="resetModal" ref="modal-merchant" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Create a Merchant Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label>Login Username</label>
                 <input
                    v-model.trim="formData.username"
                    @input="formData.username = $event.target.value.toLowerCase()"
                    type="text"
                    class="form-control"
                    placeholder="Username"
                    name="username"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.username.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.username.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.username.required"
                       >Username is required.</span
                       >
                    <span v-else-if="!$v.formData.username.validUsername"
                       >Username must contain only lowercase alphabets and numbers.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Merchant Name</label>
                 <input
                    v-model.trim="formData.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              
              <div class="mb-3 col-12 col-lg-6">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.formData.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.formData.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.formData.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="formData.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.formData.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.formData.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.formData.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.formData.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Website</label>
                 <input
                    v-model.trim="formData.websiteUrl"
                    type="text"
                    class="form-control"
                    placeholder="Website"
                    name="WebsiteUrl"
                    />
                    <!-- :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.websiteUrl.$error,
                    }" -->
                 <!-- <div
                    v-if="modalSubmit && $v.formData.websiteUrl.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.websiteUrl.required"
                       >Website url is required.</span
                       >
                 </div> -->
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model.trim="formData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"/>
              </div>

              <div class="mb-3 col-12 col-lg-6">
                 <label>Callback Url (Payin)</label>
                 <input
                    type="text"
                    v-model.trim="formData.callBackUrl"
                    class="form-control"
                    placeholder="Callback Url"
                    name="callBackUrl"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Whitelist IP Addresses (Payin &amp; Payout) <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small></label>
                 <input
                    v-model.trim="formData.whitelistIP"
                    type="text"
                    class="form-control"
                    placeholder="Enter multiple IP addresses separated by commas (,)"
                    name="whitelistIP"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Financial Group <span class="text-muted font-size-11 fw-normal">This action is allowed for admins.</span></label>
                 <select
                    v-if="account_type=='admin'"
                    v-model="formData.group"
                    class="form-select text-capitalize" 
                    :disabled="loading || disabled">
                    <option value="" class="text-muted">Select</option>
                    <option v-for="(value,index) in financialGroup" :key="index" :value="value">{{value.name}}</option>
                 </select>
                 <select
                    v-else
                    class="form-select text-capitalize" 
                    :disabled="true">
                    <option value="" class="text-muted">-----------</option>
                 </select>
                 <!-- 
                   :class="{
                    'is-invalid':
                    modalSubmit && $v.formData.group.$error,
                  <div
                    v-if="modalSubmit && $v.formData.group.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formData.group.required"
                       >Group is required.</span
                       >
                 </div> -->
              </div>
              <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
              <hr>
              <div class="mb-3">
                <label>Reseller</label>
              
                <multiselect v-model="selectedReseller" :options="reseller" label="account_name" :class="{'mb-2':selectedReseller.account_db_code!=='all' && selectedReseller.account_db_code!=='-1'}" placeholder="Select Reseller" :close-on-select="true" :allow-empty="false" deselect-label="" :show-on-select="true" :disabled="loading" @input="getData()">
                      <template slot="singleLabel" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>

                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                            {{ props.option.account_name }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span v-if="props.option.account_db_code!=='-1'">
                          <span class="option__title">
                            <span v-if="props.option.reseller_level!=='' && account_type=='admin'">
                              <span class="badge bg-info font-size-12" v-if="props.option.reseller_level=='0'"><i class="bi bi bi-house-door"></i></span>
                              <span class="badge bg-info font-size-12" v-else>Level {{ props.option.reseller_level }}</span>
                            </span>
                            
                            {{ props.option.account_name }} 
                          </span>
                        </span>
                        <span v-else>
                          {{ props.option.account_name }} 
                        </span>
                      </template>
                      <span slot="noResult">Oops! No reseller found. </span>
                    </multiselect>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="formData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> No Contract Available</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
                <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                  <thead class="custom_top_data_bar text-uppercase">
                    <tr>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                      <th><span class="placeholder col-12 col-lg-8"></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                    <tr>
                      <td>
                      </td>
                      <td class="align-top text-wrap">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td class="align-top">
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                    </tr>
                    <tr>
                      <td><span class="placeholder col-12 col-lg-8"></span></td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                      <td>
                        <span class="placeholder col-12 col-lg-8"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3" variant="info" ref="dropdown" v-if="formData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <!-- <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div> -->
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
               
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1 d-none">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap" >
                      <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                      <input class="form-check-input" type="checkbox" :id="index" v-model="formData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                      <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                    </div>
                  </div>
                  <div class="table-responsive text-nowrap font-size-14 mb-3">
                    <table class="table table-bordered align-middle custom-table mb-0">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charge</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th class="d-none">Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet (UPI) Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{parseFloat(uplineCost[index].payin_rate).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{parseFloat(uplineCost[index].payin_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{parseFloat(uplineCost[index].payout_rate).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{parseFloat(uplineCost[index].payout_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="d-none">
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{parseFloat(uplineCost[index].settlement_rate).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{parseFloat(uplineCost[index].settlement_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{parseFloat(uplineCost[index].ewallet_rate).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{parseFloat(uplineCost[index].ewallet_extra_charge).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="4" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges ({{ value.currency.$model || '-' }})</td></tr>                      
                        <tr>
                          <td>Merchant 
                            <input type="text" class="form-control" v-model="formData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap d-none">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="4" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Merchant Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="d-none">
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="addMerchantRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-edit" size="xl" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Edit Merchant Account
           </h5>
        </template>
        <div class="row align-items-top">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Login Username</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ updateData.username || '-' }}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                <div class="row">
                  <div class="col-12 col-md-4 mb-2 mb-md-0">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">Account Status</label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <div v-if="uplineInfo.length"> 
                          <input class="form-check-input" type="checkbox" role="switch" :disabled="loading || (uplineInfo[uplineInfo.length - 1].status_flag==0)" v-if="uplineInfo[uplineInfo.length - 1].status_flag==0">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.status" true-value="1" false-value="0" :disabled="loading" v-else>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 mb-2 mb-md-0">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">API Connect</label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.api_enabled" true-value="1" false-value="0" :disabled="loading">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 mb-2 mb-md-0">
                    <div class="align-items-center">
                      <label class="mb-1 me-2">Login Block
                        <span v-if="formEditData.login_block==1" class="ms-1 badge bg-soft-danger rounded-pill font-size-12">Blocked</span>
                      </label>
                      <div>
                        <div class="form-check form-switch form-check-custom ms-1 mb-0 font-size-14">
                          <input class="form-check-input" type="checkbox" role="switch" v-model="formEditData.login_block" true-value="1" false-value="0" :disabled="loading">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Code</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.merchant_code || '-'}}</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Merchant Key</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.merchant_key || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Merchant Name</label>
                 <input
                    v-model="formEditData.name"
                    type="text"
                    class="form-control"
                    placeholder="Merchant Name"
                    name="name"
                    :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.name.$error,
                    }"
                    />
                 <div
                    v-if="modalSubmit && $v.formEditData.name.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.name.required"
                       >Name is required.</span
                       >
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Website</label>
                 <input
                    v-model="formEditData.websiteUrl"
                    type="text"
                    class="form-control"
                    placeholder="Website"
                    name="WebsiteUrl"
                    />
                    <!-- :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.websiteUrl.$error,
                    }" -->
                 <!-- <div
                    v-if="modalSubmit && $v.formEditData.websiteUrl.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.websiteUrl.required"
                       >Website url is required.</span
                       >
                    <span v-else-if="!$v.formEditData.websiteUrl.url"
                       >Please enter a valid website url.</span
                       >
                 </div> -->
              </div>
           

              <div class="mb-3 col-12 col-lg-6">
                 <label>Callback Url (Payin)</label>
                 <input
                    type="text"
                    v-model.trim="formEditData.callBackUrl"
                    class="form-control"
                    placeholder="Callback Url"
                    name="callBackUrl"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Whitelist IP Addresses (Payin &amp; Payout) <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small></label>
                 <input
                    v-model.trim="formEditData.whitelistIP"
                    type="text"
                    class="form-control"
                    placeholder="Enter multiple IP addresses separated by commas (,)"
                    name="whitelistIP"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Financial Group <span class="text-muted font-size-11 fw-normal">This action is allowed for admins.</span></label>
                 <select
                    v-if="account_type=='admin'"
                    v-model="formEditData.group"
                    class="form-select text-capitalize" 
                    :disabled="disabled">
                    <option value="" class="text-muted">Select</option>
                    <option v-for="(value,index) in financialGroup" :key="index" :value="value">{{value.name}}</option>
                 </select>
                 <select
                    v-else
                    class="form-select text-capitalize" 
                    :disabled="disabled">
                    <option value="" class="text-muted">-----------</option>
                 </select>
                 <!-- :class="{
                    'is-invalid':
                    modalSubmit && $v.formEditData.group.$error}"
                 <div
                    v-if="modalSubmit && $v.formEditData.group.$error"
                    class="invalid-feedback"
                    >
                    <span v-if="!$v.formEditData.group.required"
                       >Group is required.</span
                       >
                 </div> -->
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Whitelist IP Addresses (Login) <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small> </label>
                 <input
                    v-model="formEditData.whitelistLoginIP"
                    type="text"
                    class="form-control"
                    placeholder="Enter multiple IP addresses separated by commas (,)"
                    name="Whitelist IP Addresses (Login)"
                    />
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label>Remarks</label>
                 <input
                    v-model="formEditData.remarks"
                    type="text"
                    class="form-control"
                    placeholder="Remarks"
                    name="Remarks"
                    />
              </div>
              <div class="mb-4 col-12 col-lg-12">
              <label>Password</label>
                  <div> <button class="btn btn-info py-2 font-size-15 rounded" @click="showResetPasswordModal()">
                      <i class="uil uil-lock-alt"></i> Reset Password</button></div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled</label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else> -</div>
              </div>
              
              <table class="table table-bordered align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr>
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
              
              <div>
                <b-dropdown id="dropdown-grouped" class="mb-3 d-none" variant="info" ref="dropdown2" v-if="formEditData.contract_selected.length && !loading">
                  <template #button-content>
                    <i class="uil uil-edit-alt"></i> Quick Update
                  </template>
                  <b-dropdown-header>
                    Both the total rate and extra charges will increase <br> based on the input values below.
                  </b-dropdown-header>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group id="dropdown-group-1" header="">
                    <b-dropdown-form class="p-3 pt-2">
                      <div class="row">
                        <div class="col-12 mb-3">
                          <div class="d-flex align-items-center mb-3">
                            <div class="w-100 me-1">Set All Inputs</div>
                              <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.all_value" placeholder="All Inputs" maxlength="6" @input="inputNumberOnlyV2($event, 'quickUpdate','all_value'), updateAllInput()" >  
                          </div>
                          <b-dropdown-divider></b-dropdown-divider>
                        </div>
                        <div class="col-12">
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payin</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payin_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payin_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payin_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Payout</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.payout_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.payout_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','payout_extra_charge')" >
                          </div>
                          <div class="d-flex align-items-center mb-2">
                            <div class="w-100 me-1">Settlement</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.settlement_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.settlement_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','settlement_extra_charge')" >
                          
                          </div>
                          <div class="d-flex align-items-center">
                            <div class="w-100 me-1">E-Wallet</div>
                            <input type="text" inputmode="decimal" class="form-control text-center me-1" v-model="quickUpdate.ewallet_rate" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_rate')" >  
                            <input type="text" inputmode="decimal" class="form-control text-center" v-model="quickUpdate.ewallet_extra_charge" placeholder="Charge" maxlength="8" @input="inputNumberOnlyV2($event, 'quickUpdate','ewallet_extra_charge')" >
                          </div>
                        </div>
                      </div>
                      </b-dropdown-form> 
                  </b-dropdown-group>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-form class="p-3 py-1">
                   <div class="d-flex gap-2">
                   <button type="button" class="btn btn-secondary w-100" @click="resetRateAndExtraCharge('formEditData')">Reset All</button>
                   <button type="button" class="btn btn-info  w-100" @click="quickUpdateRateAndExtraCharge('formEditData')">Update All</button>
                  </div>
                  </b-dropdown-form>
                </b-dropdown>
               
              </div>
              <div v-if="!loading">
                <div class="mb-3" v-for="(value, index) in $v.formEditData.contract_selected.$each.$iter" :key="index">
                  <div class="d-flex text-lg-end mb-1 d-none">
                      <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency.$model || '-' }})</h4>
                      <!-- <span class="text-danger">upline_disable: [{{ uplineCost[index].upline_disable }}]</span> -->
                      <div class="form-check form-switch form-check-custom ms-auto font-size-14 text-nowrap" >
                        <input class="form-check-input" type="checkbox" :id="index" true-value="1" false-value="0" disabled="true" v-if="uplineCost[index].upline_disable">
                        <input class="form-check-input" type="checkbox" :id="index" v-model="formEditData.contract_selected[index].status" true-value="1" false-value="0" v-else>
                        <label class="form-check-label fw-normal font-size-14" :for="index"> {{ formEditData.contract_selected[index].status == 1 ? 'Enabled' : 'Disabled' }} Contract</label>
                      </div>
                  </div>

                  <div class="table-responsive text-nowrap font-size-14 mb-4">
                    <table class="table table-bordered align-middle custom-table mb-1">
                      <thead class="custom_top_data_bar text-uppercase font-size-12">
                        <tr>
                          <th>Current Rate <br>and Extra Charge</th>
                          <th>Payin Rate <br>and Extra Charges</th>
                          <th>Payout Rate <br>and Extra Charges</th>
                          <th class="d-none">Settlement Rate<br>and Extra Charges</th>
                          <th>E-Wallet (UPI) Rate <br> and Extra Charges</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Company/Upline</td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{uplineCost[index].payin_rate.toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{uplineCost[index].payin_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{uplineCost[index].payout_rate.toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{uplineCost[index].payout_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="d-none">
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{uplineCost[index].settlement_rate.toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{uplineCost[index].settlement_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                              {{uplineCost[index].ewallet_rate.toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{uplineCost[index].ewallet_extra_charge.toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="5" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td></tr>                      
                        <tr>
                          <td>Merchant 
                            <input type="text" class="form-control" v-model="formEditData.contract_selected[index].currency" hidden>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_rate.$error}" 
                                  v-model="value.payin_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','payin_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_rate.required"
                                    >The payin rate is required.</span
                                    >
                                  <span v-else-if="!value.payin_rate.between"
                                    >The payin rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payin_extra_charge.$error}" 
                                  v-model="value.payin_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','payin_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payin_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payin_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payin_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_rate.$error}" 
                                  v-model="value.payout_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','payout_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_rate.required"
                                    >The payout rate is required.</span
                                    >
                                  <span v-else-if="!value.payout_rate.between"
                                    >The payout rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.payout_extra_charge.$error}" 
                                  v-model="value.payout_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','payout_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.payout_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.payout_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.payout_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap d-none">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_rate.$error}" 
                                  v-model="value.settlement_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','settlement_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_rate.required"
                                    >The settlement rate is required.</span
                                    >
                                  <span v-else-if="!value.settlement_rate.between"
                                    >The settlement rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.settlement_extra_charge.$error}" 
                                  v-model="value.settlement_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','settlement_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.settlement_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.settlement_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.settlement_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                          <td class="align-top text-wrap">
                            <div class="d-flex">
                              <div class="flex-fill me-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_rate.$error}" 
                                  v-model="value.ewallet_rate.$model" placeholder="Rate" maxlength="5" @input="inputNumberOnlyV3($event, 'formEditData','ewallet_rate',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_rate.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_rate.required"
                                    >The e-wallet rate is required.</span
                                    >
                                  <span v-else-if="!value.ewallet_rate.between"
                                    >The e-wallet rate should be above 0.10% but below 50%.</span
                                    > 
                                </div>
                              </div>
                              <div class="flex-fill ms-1">
                                <input type="text" inputmode="decimal" class="form-control text-center" :class="{ 'is-invalid': modalSubmit && value.ewallet_extra_charge.$error}" 
                                  v-model="value.ewallet_extra_charge.$model" placeholder="Extra charge" maxlength="8" @input="inputNumberOnlyV3($event, 'formEditData','ewallet_extra_charge',index)" >
                                <div
                                  v-if="modalSubmit && value.ewallet_extra_charge.$error"
                                  class="invalid-feedback"
                                  >
                                  <span v-if="!value.ewallet_extra_charge.required"
                                    >The extra charge is required. </span
                                    >
                                  <span v-else-if="!value.ewallet_extra_charge.between"
                                    >The extra charge can be 0.00 or any value above 0.00.</span
                                    > 
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr><td colspan="4" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Merchant Rate and Extra Charges  ({{ value.currency.$model || '-' }})</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].payin_rate) + parseFloat(value.payin_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].payin_extra_charge) + parseFloat(value.payin_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].payout_rate) + parseFloat(value.payout_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].payout_extra_charge) + parseFloat(value.payout_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td class="d-none">
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].settlement_rate) + parseFloat(value.settlement_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].settlement_extra_charge) + parseFloat(value.settlement_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex text-center">
                              <div class="flex-fill">
                                {{ parseFloat(parseFloat(uplineCost[index].ewallet_rate) + parseFloat(value.ewallet_rate.$model || 0)).toFixed(2)}}%
                              </div>
                              <div class="flex-fill">
                              + {{ parseFloat(parseFloat(uplineCost[index].ewallet_extra_charge) + parseFloat(value.ewallet_extra_charge.$model || 0)).toFixed(2)}}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-edit'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="updateMerchantRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Updating...</span>
                 <span v-else> Update </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-contract" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-usd-circle me-1 text-primary"></i> Merchant's Contract
           </h5>
        </template>
        <div class="row align-items-center">
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Username</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14">{{ updateData.account_username }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Name</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Merchant Code</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.merchant_code || '-'}}</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Merchant Key</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.merchant_key || '-'}}</span></div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Website</label>
                 <div>
                  <span class="text-muted font-size-14">
                    <a :href="formEditData.websiteUrl" target="_blank">{{ formEditData.websiteUrl || '-'}} </a>
                  </span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Remarks</label>
                 <div>
                  <span class="text-muted font-size-14">{{ formEditData.remarks || '-'}} </span>
                </div>
              </div>
              <div class="mb-3 col-12 col-lg-6">
                 <label class="mb-1">Callback Url (Payin)</label>
                 <div>
                  <span class="text-muted font-size-14">
                    <a :href="'https://'+formEditData.callBackUrl" target="_blank">{{ formEditData.callBackUrl || '-'}} </a>
                  </span>
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Whitelist IP Addresses (Payin &amp; Payout)</label>
                 <div>
                  <span class="text-muted font-size-14">{{ formEditData.whitelistIP || '-'}} </span>
                </div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Financial Group</label>
                 <div v-if="formEditData.group.name"><span class="badge bg-soft-primary font-size-14">{{ formEditData.group.name || '-'}}</span></div>
                 <div v-else><span class="badge bg-soft-danger font-size-14">No Selected Group</span></div>
              </div>
              <div class="mb-4 col-12 col-lg-6">
                 <label class="mb-1">Code for Support</label>
                 <div><span class="badge bg-soft-primary font-size-14">{{ formEditData.code_for_support_skype || '-'}}</span></div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>

              </div>
              <div class="mb-3 d-none">
                <label>Currency Enabled </label>
                <div v-if="formEditData.contract_selected.length">
                  <span class="badge bg-soft-success rounded-pill font-size-14 py-1 px-2 me-1 mb-2" v-for="(value, index) in formEditData.contract_selected" :key="index">
                    <span v-if="value.status==1">{{value.name}}</span>
                  </span>
                   <div v-if="!formEditData.contract_selected.some(e => e.status==1)"> -</div>
                </div>
                <div v-else>-</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table border align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-3" v-for="(value, index) in contracts" :key="index">
                <div v-if="value.status==1">
                <div class="d-flex text-lg-end mb-1">
                    <h4 class="font-size-14">Currency Rate and Extra Charges ({{ value.currency || '-' }})</h4>
                </div>

                <div class="table-responsive text-nowrap font-size-14 mb-3">
                  <table class="table border align-middle mb-0">
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Contract <br>Currency</th>
                        <th>Payin Rate <br>and Extra Charges</th>
                        <th>Payout Rate <br>and Extra Charges</th>
                        <th class="d-none">Settlement Rate<br>and Extra Charges</th>
                        <th>E-Wallet (UPI) Rate <br> and Extra Charges</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Company/Upline</td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                            {{uplineCost[index].payin_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                             + {{uplineCost[index].payin_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                            {{uplineCost[index].payout_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                             + {{uplineCost[index].payout_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="d-none">
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                            {{uplineCost[index].settlement_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                             + {{uplineCost[index].settlement_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                            {{uplineCost[index].ewallet_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                             + {{uplineCost[index].ewallet_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td colspan="4" class="bg-light py-2 fw-medium text-dark text-center font-size-13">Reseller Earning Rate and Extra Charges  ({{ value.currency || '-' }})</td></tr>                      
                      <tr class="bg-soft-success">
                        <td>Merchant 
                          <input type="text" class="form-control" v-model="value.currency" hidden>
                        </td>
                        <td class="text-wrap text-start">
                          <div class="d-flex">
                            <div class="flex-fill">
                             {{value.payin_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                            +  {{value.payin_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-start">
                          <div class="d-flex">
                            <div class="flex-fill">
                             {{value.payout_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                            + {{value.payout_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-start d-none">
                          <div class="d-flex">
                            <div class="flex-fill">
                              {{value.settlement_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                            +  {{value.settlement_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                        <td class="text-wrap text-start">
                          <div class="d-flex">
                            <div class="flex-fill">
                              {{value.ewallet_rate.toFixed(2)}}%
                            </div>
                            <div class="flex-fill">
                            +  {{value.ewallet_extra_charge.toFixed(2)}}
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr><td colspan="4" class="bg-light py-2 fw-medium text-dark text-center font-size-13">{{ updateData.account_name }} - Rate and Extra Charges  ({{ value.currency || '-' }})</td>
                      </tr>
                      <tr>
                        <td>{{ value.currency || '-' }} </td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                              {{ ownCost[index].payin_rate.toFixed(2) }}%
                            </div>
                            <div class="flex-fill">
                             + {{ ownCost[index].payin_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                              {{ ownCost[index].payout_rate.toFixed(2) }}%
                            </div>
                            <div class="flex-fill">
                             + {{ ownCost[index].payout_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td class="d-none">
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                              {{ ownCost[index].settlement_rate.toFixed(2) }}%
                            </div>
                            <div class="flex-fill">
                             + {{ ownCost[index].settlement_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="d-flex text-start">
                            <div class="flex-fill">
                              {{ ownCost[index].ewallet_rate.toFixed(2) }}%
                            </div>
                            <div class="flex-fill">
                             + {{ ownCost[index].ewallet_extra_charge.toFixed(2) }}
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-contract'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-wallet" size="xl" centered hide-footer content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-wallet me-1 text-primary"></i> Merchant's Wallet
           </h5>
        </template>
        <div class="row align-items-center">
              <div class="mb-3">
                 <label class="mb-1">Merchant's Name</label>
                 <div>
                  <span class="badge bg-soft-primary font-size-14" v-if="updateData.account_db_code!=='-1'">{{ updateData.account_name }}</span>
                  <span class="badge bg-soft-primary font-size-14" v-else>David</span>
                </div>
              </div>
              <hr>
              <div v-if="updateData.username!==defaultName" class="mb-3">
                <label class="mb-1 text-capitalize">Merchant under below reseller</label>
                <div class="form-control bg-light border-0" v-if="uplineInfo.length"> {{ uplineInfo[uplineInfo.length - 1].name }}</div>
              </div>
              <div class="table-responsive text-nowrap font-size-14">
              <table class="table align-middle placeholder-glow" v-if="loading">
                <thead class="custom_top_data_bar text-uppercase">
                  <tr>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                    <th><span class="placeholder col-12 col-lg-8"></span></th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="d-none">
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td></tr>                      
                  <tr class="d-none">
                    <td>
                    </td>
                    <td class="align-top text-wrap">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td class="align-top">
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                  <tr class="d-none"><td colspan="5" class="bg-light fw-medium text-dark text-center"><span class="placeholder col-12 col-lg-8"></span></td>
                  </tr>
                  <tr>
                    <td><span class="placeholder col-12 col-lg-8"></span></td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                    <td>
                      <span class="placeholder col-12 col-lg-8"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mb-1" v-if="!loading">
                <div>
                <div class="d-flex text-lg-end">
                    <h4 class="font-size-15">Wallet</h4>
                </div>

                <div class="table-responsive table-hover text-nowrap font-size-14 mb-2">
                  <table class="table align-middle">
                    <thead class="custom_top_data_bar text-uppercase font-size-12">
                      <tr>
                        <th>Currency</th>
                        <th class="text-end">Payin</th>
                        <th class="text-end">E-Wallet (UPI)</th>
                        <th class="text-end">Payout</th>
                        <th class="text-end">Settlement</th>
                        <th class="text-end">Contract Balance</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr v-for="(value, index) in wallets" :key="index">
                        <td>{{value.currency}}</td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payin, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_ewallet, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_payout, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.total_settlement, true)}}
                        </td>
                        <td class="text-end">
                          {{ convertCurrencyFormat(value.contract_balance, true)}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              </div>
              <div class="col-12">
                  <hr>
                  <div class="d-grid gap-2 d-md-block text-end">
                    <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-wallet'].hide()">Cancel</button>
                  </div>
              </div>
            </div>
     </b-modal>
     <b-modal @hidden="resetModal2" ref="modal-reset-password" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-user me-1 text-primary"></i> Reset Password
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-lg-12">
              <label>Username </label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_username || '-' }}</span></div>
          
          </div>
          <div class="mb-3 col-12 col-lg-12">
                 <label>Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.password"
                       :type="passwordFieldType"
                       name="password"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.password.$error,
                       }"
                       placeholder="Password"
                       />
                    <div
                       v-if="modalSubmit && $v.resetPassword.password.$error"
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.password.required"
                          >Password is required.</span
                          >
                       <span v-if="!$v.resetPassword.password.minLength"
                          >Password must be at least 8 characters.</span
                          >
                       <span v-else-if="!$v.resetPassword.password.alpha">
                       Password must be at least 8 characters and contain at least one number, as well as both lowercase and uppercase letters, along with special characters (e.g., HengHeng357#@, Admin12#@, etc.).</span
                          >
                    </div>
                    <div
                       id="eye"
                       class="user-select-none"
                       @click="switchVisibility()"
                       >
                       <i
                          :class="classicon"
                          id="eyetype"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              <div class="mb-3 col-12 col-lg-12">
                 <label>Confirm Password</label>
                 <div class="position-relative">
                    <input
                       v-model="resetPassword.confirmPassword"
                       :type="passwordFieldType2"
                       name="confirmPassword"
                       class="form-control"
                       :class="{
                       'is-invalid':
                       modalSubmit && $v.resetPassword.confirmPassword.$error,
                       }"
                       placeholder="Confirm Password"
                       />
                    <div
                       v-if="
                       modalSubmit && $v.resetPassword.confirmPassword.$error
                       "
                       class="invalid-feedback"
                       >
                       <span v-if="!$v.resetPassword.confirmPassword.required"
                          >Confirm password is required.</span
                          >
                       <span
                          v-else-if="
                          !$v.resetPassword.confirmPassword.sameAsPassword
                          "
                          >Confirm password does not match the new
                       password.</span
                          >
                    </div>
                    <div
                       id="eye2"
                       class="user-select-none"
                       @click="switchVisibility2()"
                       >
                       <i
                          :class="classicon2"
                          id="eyetype2"
                          aria-hidden="true"
                          ></i>
                    </div>
                 </div>
              </div>
              
          <p class="text-muted font-size-12"><i class="uil uil-exclamation-triangle font-size-15 text-warning me-2"></i> Password is Case Sensitive. </p>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                 <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-reset-password'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="resetPasswordRequest" :disabled="modalLoading || loading">
                 <span v-if="modalLoading"> Reseting...</span>
                 <span v-else> Reset Password </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-create-payin" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-coin me-1 text-primary"></i> Payin - Create Payment
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12" :class="{'col-lg-6':account_type=='admin'}">
              <label class="mb-1">Merchant</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_name || '-' }}</span></div>
          </div>
          <div class="mb-3 col-12 col-lg-6" v-if="account_type=='admin'">
              <label class="mb-1">Financial Group</label>
              <div v-if="createPayin.group.name"><span class="badge bg-soft-primary font-size-14">{{ createPayin.group.name || '-' }}</span></div>
              <div v-else><span class="badge bg-soft-danger font-size-14">No Selected Group</span></div>
          </div>
          <div class="mb-3 col-12 col-lg-6">
              <label class="mb-1">Merchant Code</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ createPayin.merchant_code || '-'}}</span></div>
          </div>
          <div class="mb-4 col-12 col-lg-6">
              <label class="mb-1">Merchant Key</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ createPayin.merchant_key || '-'}}</span></div>
          </div>
          <div class="mb-3 col-12 col-lg-12">
              <label>Amount</label>
              <input
                v-model="createPayin.amount"
                autocomplete="off"
                type="text"
                inputmode="decimal"
                @input="inputNumberOnlyV2($event, 'createPayin','amount')" 
                class="form-control"
                placeholder="Amount"
                :disabled="modalLoading || loading || !createPayin.group"
                name="amount"
                :class="{
                'is-invalid':
                modalSubmit && $v.createPayin.amount.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.createPayin.amount.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.createPayin.amount.required"
                    >Amount is required.</span
                    >
              </div>
          </div>
          <div class="mb-3 col-12 col-lg-12">
              <label class="d-block">Payment Type</label>
              <div>
                <!-- <input type="radio" v-model="createPayin.type" value="RANDOM" class="btn-check" name="payment_type" id="RANDOM" autocomplete="off" :disabled="modalLoading || loading || !createPayin.group" >
                <label class="btn btn-outline-info me-1" :class="{'btn-info':createPayin.type=='RANDOM'}" for="RANDOM">Random</label> -->

                <input type="radio" v-model="createPayin.type" value="IMPS"  class="btn-check" name="payment_type" id="IMPS" autocomplete="off" :disabled="modalLoading || loading || !createPayin.group">
                <label class="btn btn-outline-info me-1" :class="{'btn-info':createPayin.type=='IMPS'}" for="IMPS">IMPS</label>

                <input type="radio" v-model="createPayin.type" value="UPI" class="btn-check" name="payment_type" id="UPI" autocomplete="off" :disabled="modalLoading || loading || !createPayin.group">
                <label class="btn btn-outline-info me-1" :class="{'btn-info':createPayin.type=='UPI'}" for="UPI">UPI</label>
              </div>
              <div
                v-if="modalSubmit && $v.createPayin.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.createPayin.type.required"
                    >Payment Type is required.</span
                    >
              </div>
            </div>
          <div class="mb-3 col-12 col-lg-12 d-none">
              <label>Financial Group</label>
              <select 
                v-model="createPayin.group"
                class="form-select text-capitalize" 
                :class="{
                'is-invalid':
                modalSubmit && $v.createPayin.group.$error}" disabled="true">
                <option value="" class="text-muted">Select</option>
                <option v-for="(value,index) in financialGroup" :key="index" :value="value">{{value.name}}</option>
              </select>
              <div
                v-if="modalSubmit && $v.createPayin.group.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.createPayin.group.required"
                    >Group is required.</span
                    >
              </div>
          </div>
          <!-- <div class="mb-3 col-12 col-lg-12">
              <label>Name</label>
              <input
                type="text"
                :class="{
                'is-invalid':
                modalSubmit && $v.createPayin.name.$error}"
                v-model.trim="createPayin.name"
                class="form-control"
                placeholder="Name"
                name="Name"
                />
                <div
                v-if="modalSubmit && $v.createPayin.name.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.createPayin.name.required"
                    >Name is required.</span
                    >
              </div>
          </div> -->
          <div class="mb-3 col-12 col-lg-12">
              <label>Callback Url (Payin)</label>
              <input
                type="text"
                :class="{
                'is-invalid':
                modalSubmit && $v.createPayin.callBackUrl.$error}"
                v-model.trim="createPayin.callBackUrl"
                class="form-control"
                placeholder="Callback Url"
                name="callBackUrl"
                :disabled="modalLoading || loading || !createPayin.group"
                />
                <div
                v-if="modalSubmit && $v.createPayin.callBackUrl.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.createPayin.callBackUrl.required"
                    >Callback url is required.</span
                    >
              </div>
          </div>
           <div class="col-12">
              <hr>
              <div class="d-grid gap-2 d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-md-2" @click="$refs['modal-merchant-create-payin'].hide()">Cancel</button>
                 <button type="button" class="btn fw-medium btn-info" @click="createMerchantPayin" :disabled="modalLoading || loading || !createPayin.group">
                 <span v-if="modalLoading"> Creating...</span>
                 <span v-else> Create </span>
                 <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                 </button>
              </div>
           </div>
        </div>
     </b-modal>
     <b-modal @hidden="resetModal" ref="modal-merchant-update-balance" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="uil uil-wallet me-1 text-primary"></i> Update Balance
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Merchant</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.account_name || '-' }}</span></div>
          </div>
          
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Merchant Code</label>
              <div><span class="badge bg-soft-primary font-size-14">{{ updateData.merchant_code || '-'}}</span></div>
          </div>

          <div class="mb-3 col-12 col-lg-4">
             
          </div>
         
          <div class="mb-1 col-12 col-lg-4">
              <label class="d-block">Adjust by Amount (+/-)</label>
              <div>
                <input type="radio" v-model="updateBalance.type" value="+" class="btn-check" name="adjust_type" id="adding" autocomplete="off" :disabled="modalLoading || loading" >
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateBalance.type=='+'}" for="adding">Addition (+)</label>

                <input type="radio" v-model="updateBalance.type" value="-"  class="btn-check" name="adjust_type" id="subtracting" autocomplete="off" :disabled="modalLoading || loading">
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateBalance.type=='-'}" for="subtracting">Subtraction (-)</label>
              </div>
              <div
                v-if="modalSubmit && $v.updateBalance.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateBalance.type.required"
                    > Type is required.</span
                    >
              </div>
           </div>

          <div class="mb-3 col-12 col-lg-8">
              <label>Amount</label>
              <input
                v-model="updateBalance.amount"
                autocomplete="off"
                type="text"
                inputmode="decimal"
                @input="inputNumberOnlyV2($event, 'updateBalance','amount'), detectOperator($event)" 
                class="form-control"
                placeholder="Amount"
                :disabled="modalLoading || loading"
                name="amount"
                :class="{
                'is-invalid':
                modalSubmit && $v.updateBalance.amount.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.updateBalance.amount.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateBalance.amount.required"
                    >Amount is required.</span
                    >
              </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-3 bg-light rounded">
              <div class="row">
              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
              <label class="mb-0 fw-normal">Current Balance  </label>
              <div>
              <span class="fw-semibold font-size-20" v-if="wallets.length"> 
                {{ convertCurrencyFormat(wallets[0].contract_balance,true)}} 
              </span>
              <span v-else class="fw-semibold font-size-20">0.00</span>
              </div>
              </div>

              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Amount</label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="updateBalance.amount">
                    <span v-if="updateBalance.type=='+'" class="text-success">
                      {{ updateBalance.type }} {{ convertCurrencyFormat(updateBalance.amount,true)}}
                    </span> 
                    <span v-if="updateBalance.type=='-'" class="text-danger">
                      {{ updateBalance.type }} {{ convertCurrencyFormat(updateBalance.amount,true)}}
                    </span> 
                  
                  </span>
                  <span v-else class="fw-semibold font-size-20">
                    {{updateBalance.type}} 0.00</span>
                  </div>
              </div>
              <div class="mb-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Est. After Balance  </label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="wallets.length && updateBalance.amount"> 
                    <span v-if="updateBalance.type=='+'">
                    {{ convertCurrencyFormat(wallets[0].contract_balance + parseFloat(updateBalance.amount),true) }}
                    </span>
                    <span v-if="updateBalance.type=='-'">
                    {{ convertCurrencyFormat(wallets[0].contract_balance - parseFloat(updateBalance.amount),true) }}
                    </span>
                  </span>
                  <span v-else class="fw-semibold font-size-20">0.00</span>
                  </div>
              </div>
            </div>
            </div>
          </div>
         
          
          <div class="mb-3 col-12 col-lg-6">
            <label>Password</label>
            <div class="position-relative">
              <input
                  v-model="updateBalance.password"
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.updateBalance.password.$error,
                  }"
                  placeholder="Password"
                  />
              <div
                  v-if="modalSubmit && $v.updateBalance.password.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.updateBalance.password.required"
                    >Password is required.</span
                    >
              </div>
              <div
                  id="eye"
                  class="user-select-none"
                  @click="switchVisibility()"
                  >
                  <i
                    :class="classicon"
                    id="eyetype"
                    aria-hidden="true"
                    ></i>
              </div>
            </div>
          </div>
          <div class="mb-3 col-12 col-lg-6">
              <label>Remarks</label>
              <input
                v-model="updateBalance.remarks"
                type="text"
                class="form-control"
                placeholder="Remarks"
                name="Remarks"
                />
          </div>
           <div class="col-12">
              <hr>
              <div class="d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-2" @click="$refs['modal-merchant-update-balance'].hide()">Cancel</button>

                <div class="d-inline" v-if="updateBalance.type=='-' && wallets.length">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(wallets[0].contract_balance ) - parseFloat(updateBalance.amount))>=0" @click="updateWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </div>  
                <div class="d-inline" v-if="updateBalance.type=='+' && wallets.length">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(wallets[0].contract_balance ) + parseFloat(updateBalance.amount))>=0" @click="updateWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </div>
              </div>
           </div>
        </div>
     </b-modal>

     <b-modal @hidden="resetModal" ref="modal-merchant-update-freeze-balance" size="lg" centered hide-footer  :no-close-on-backdrop="true" content-class="overflow-hidden">
        <template #modal-title>
           <h5 class="text-info mb-0 text-dark">
              <i class="bi bi-snow2 me-1 text-danger"></i> Update Frozen Balance
           </h5>
        </template>
        <div class="row align-items-top">
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Merchant</label>
              <div><span class="badge bg-soft-danger font-size-14">{{ updateData.account_name || '-' }}</span></div>
          </div>
          
          <div class="mb-3 col-12 col-lg-4">
              <label class="mb-1">Merchant Code</label>
              <div><span class="badge bg-soft-danger font-size-14">{{ updateData.merchant_code || '-'}}</span></div>
          </div>

          <div class="mb-3 col-12 col-lg-4">
             
          </div>
         
          <div class="mb-1 col-12 col-lg-4">
              <label class="d-block">Adjust by Amount (+/-)</label>
              <div>
                <input type="radio" v-model="updateFreezeBalance.type" value="+" class="btn-check" name="adjust_type" id="adding" autocomplete="off" :disabled="modalLoading || loading" >
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateFreezeBalance.type=='+'}" for="adding">Addition (+)</label>

                <input type="radio" v-model="updateFreezeBalance.type" value="-"  class="btn-check" name="adjust_type" id="subtracting" autocomplete="off" :disabled="modalLoading || loading">
                <label class="btn btn-outline-info me-1" :class="{'btn-info':updateFreezeBalance.type=='-'}" for="subtracting">Subtraction (-)</label>
              </div>
              <div
                v-if="modalSubmit && $v.updateFreezeBalance.type.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateFreezeBalance.type.required"
                    > Type is required.</span
                    >
              </div>
           </div>

          <div class="mb-3 col-12 col-lg-8">
              <label>Amount</label>
              <input
                v-model="updateFreezeBalance.amount"
                autocomplete="off"
                type="text"
                inputmode="decimal"
                @input="inputNumberOnlyV2($event, 'updateFreezeBalance','amount'), detectOperator($event)" 
                class="form-control"
                placeholder="Amount"
                :disabled="modalLoading || loading"
                name="amount"
                :class="{
                'is-invalid':
                modalSubmit && $v.updateFreezeBalance.amount.$error,
                }"
                />
              <div
                v-if="modalSubmit && $v.updateFreezeBalance.amount.$error"
                class="invalid-feedback"
                >
                <span v-if="!$v.updateFreezeBalance.amount.required"
                    >Amount is required.</span
                    >
              </div>
          </div>
          <div class="col-12 mb-3">
            <div class="p-3 bg-light rounded">
              <div class="row">
              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
              <label class="mb-0 fw-normal">Current Frozen Balance  </label>
              <div>
              <span class="fw-semibold font-size-20" v-if="updateData.contract_frozen">
                {{ convertCurrencyFormat(updateData.contract_frozen,true)}}
              </span>
              <span v-else class="fw-semibold font-size-20">0.00</span>
              </div>
              </div>

              <div class="mb-2 mb-lg-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Frozen Amount</label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="updateFreezeBalance.amount">
                    <span v-if="updateFreezeBalance.type=='+'" class="text-success">
                      {{ updateFreezeBalance.type }} {{ convertCurrencyFormat(updateFreezeBalance.amount,true)}}
                    </span> 
                    <span v-if="updateFreezeBalance.type=='-'" class="text-danger">
                      {{ updateFreezeBalance.type }} {{ convertCurrencyFormat(updateFreezeBalance.amount,true)}}
                    </span> 
                  
                  </span>
                  <span v-else class="fw-semibold font-size-20">
                    {{updateFreezeBalance.type}} 0.00</span>
                  </div>
              </div>
              <div class="mb-0 col-12 col-lg-4">
                  <label class="mb-0 fw-normal">Est. After Frozen Balance  </label>
                  <div>
                  <span class="fw-semibold font-size-20" v-if="updateData.contract_frozen && updateFreezeBalance.amount"> 
                    <span v-if="updateFreezeBalance.type=='+'">
                    {{ convertCurrencyFormat(updateData.contract_frozen + parseFloat(updateFreezeBalance.amount),true) }}
                    </span>
                    <span v-if="updateFreezeBalance.type=='-'">
                    {{ convertCurrencyFormat(updateData.contract_frozen - parseFloat(updateFreezeBalance.amount),true) }}
                    </span>
                  </span>
                  <span v-else class="fw-semibold font-size-20">0.00</span>
                  </div>
              </div>
            </div>
            </div>
          </div>
         
          
          <div class="mb-3 col-12 col-lg-6">
            <label>Password</label>
            <div class="position-relative">
              <input
                  v-model="updateFreezeBalance.password"
                  :type="passwordFieldType"
                  name="password"
                  class="form-control"
                  :class="{
                  'is-invalid':
                  modalSubmit && $v.updateFreezeBalance.password.$error,
                  }"
                  placeholder="Password"
                  />
              <div
                  v-if="modalSubmit && $v.updateFreezeBalance.password.$error"
                  class="invalid-feedback"
                  >
                  <span v-if="!$v.updateFreezeBalance.password.required"
                    >Password is required.</span
                    >
              </div>
              <div
                  id="eye"
                  class="user-select-none"
                  @click="switchVisibility()"
                  >
                  <i
                    :class="classicon"
                    id="eyetype"
                    aria-hidden="true"
                    ></i>
              </div>
            </div>
          </div>
          <div class="mb-3 col-12 col-lg-6">
              <label>Remarks</label>
              <input
                v-model="updateFreezeBalance.remarks"
                type="text"
                class="form-control"
                placeholder="Remarks"
                name="Remarks"
                />
          </div>
           <div class="col-12">
              <hr>
              <div class="d-md-block text-end">
                <button type="button" class="order-1 btn fw-medium btn-secondary me-2" @click="$refs['modal-merchant-update-freeze-balance'].hide()">Cancel</button>

                <span class="d-inline-block d-md-inline" v-if="updateFreezeBalance.type=='-'">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(updateData.contract_frozen) - parseFloat(updateFreezeBalance.amount))>=0" @click="updateFreezeWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </span>  
                <span class="d-inline-block d-md-inline" v-if="updateFreezeBalance.type=='+'">
                  <button type="button" class="btn fw-medium btn-info" v-if="(parseFloat(updateData.contract_frozen) + parseFloat(updateFreezeBalance.amount))>=0" @click="updateFreezeWalletBalance" :disabled="modalLoading || loading">
                  <span v-if="modalLoading"> Updating...</span>
                  <span v-else> Update </span>
                  <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
                
                  </button>
                  <button v-else type="button" class="btn fw-medium btn-info" :disabled="true">Update</button>
                </span>
              </div>
           </div>
        </div>
     </b-modal>
     <Common ref="commonFunc" />
  </div>
</template>
<script>
  import appConfig from "@/app.config";
  import axios from "axios";
  import Swal from "sweetalert2";
  import Common from "@/components/common";
  import Multiselect from "vue-multiselect";
  import { 
    required, 
    minLength, 
    sameAs, 
    helpers, 
    between
    //url
  } from "vuelidate/lib/validators";
  const alpha = helpers.regex("alpha", /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  const validUsername = (value) => {
    const regex = /^[a-z0-9]+$/;
    return regex.test(value);
  };
  const CancelToken = axios.CancelToken;
  let cancel;
  /**
   * Starter page
   */
  const ResellerDefault =
  {
    "account_db_code": "-1",
    "account_name": "Current Account",
    "account_type": "",
    "up_reseller_count": 0,
    "reseller_parent": [],
    "reseller_level": "",
    "reseller_code": "",
    "merchant_count": 0
  }
  export default {
    components: {
      Common,
      Multiselect
    },
    props: {
      data: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        baseCurrency: appConfig.baseCurrency,
        defaultName: appConfig.defaultAccountName,
        updateData: {},
        accessToken: '',
        accessEmail: '',
        accessPhone: '',
        accessUsername: '',
        account_type: '',
        loading: false,
        disabled:false,
        modalSubmit: false,
        modalLoading: false,
        passwordFieldType_current: "password",
        classicon_current: "uil uil-eye-slash",
        passwordFieldType: "password",
        classicon: "uil uil-eye-slash",
        passwordFieldType2: "password",
        classicon2: "uil uil-eye-slash",
        financialGroup:[],
        returnData: [],
        tabs_index:0,
        selectedReseller: ResellerDefault,
        reseller:[ResellerDefault],
        ownCost:[],
        contracts:[],
        uplineCost:[],
        wallets:[],
        uplineInfo:[],
        quickUpdate:{
          all_value:"",
          payin_rate: "",
          payin_extra_charge: "",
          payout_rate: "",
          payout_extra_charge: "",
          settlement_rate: "",
          settlement_extra_charge: "",
          ewallet_rate: "",
          ewallet_extra_charge: "",
        },
        formData: {
          username:"",
          password: "",
          confirmPassword: "",
          name:"",
          websiteUrl:"",
          whitelistIP:"",
          callBackUrl:"",
          remarks:"",
          group:"",
          contract_selected:[],
        },
        formEditData: {
          id:"",
          merchant_code:"",
          code_for_support_skype:"",
          merchant_key:"",
          name:"",
          status:"",
          status_flag:"",
          login_block:"",
          api_enabled:"",
          websiteUrl:"",
          whitelistIP:"",
          whitelistLoginIP:"",
          callBackUrl:"",
          remarks:"",
          group:"",
          contract_selected:[],
        },
        createPayin: {
          id:"",
          amount:"",
          type:"UPI",
          group:"",
          merchant_code:"",
          merchant_key:"",
          //name:"",
          callBackUrl:"",
        },
        updateBalance: {
          id:"",
          amount:"",
          type:"+",
          remarks:"",
          password:""
        },
        updateFreezeBalance: {
          id:"",
          amount:"",
          type:"+",
          remarks:"",
          password:""
        },
        resetPassword: {
          id:"",
          password: "",
          confirmPassword: ""
        },
        copyMessages:""
      };
    },
    validations: {
      formData: {
        username: {
          required,
          validUsername
        },
        name: {
          required,
        },
        // websiteUrl: {
        //   required,
        // },
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: between(0.0,50)
            },
            payin_extra_charge: {
              required,
              between: between(0,100000)
            },
            payout_rate: {
              required,
              between: between(0.0,50)
            },
            payout_extra_charge: {
              required,
              between: between(0,100000)
            },
            settlement_rate: {
              required,
              between: between(0.0,50)
            },
            settlement_extra_charge: {
              required,
              between: between(0,100000)
            },
            ewallet_rate: {
              required,
              between: between(0.0,50)
            },
            ewallet_extra_charge: {
              required,
              between: between(0,100000)
            }
         }
        },
       
      },
      formEditData: {
        name: {
          required,
        },
        // websiteUrl: {
        //   required,
        // },
        // group: {
        //   required,
        // },
        contract_selected: {
          required,
          $each: {
            currency:{
              required,
            },
            payin_rate: {
              required,
              between: between(0.0,50)
            },
            payin_extra_charge: {
              required,
              between: between(0,100000)
            },
            payout_rate: {
              required,
              between: between(0.0,50)
            },
            payout_extra_charge: {
              required,
              between: between(0,100000)
            },
            settlement_rate: {
              required,
              between: between(0.0,50)
            },
            settlement_extra_charge: {
              required,
              between: between(0,100000)
            },
            ewallet_rate: {
              required,
              between: between(0.0,50)
            },
            ewallet_extra_charge: {
              required,
              between: between(0,100000)
            }
         }
        }
      },
      createPayin: {
        id: {
          required,
        },
        amount: {
          required,
        },
        type: {
          required,
        },
        group: {
          required,
        },
        merchant_code: {
          required,
        },
        merchant_key: {
          required,
        },
        // name: {
        //   required,
        // },
        callBackUrl: {
          required,
        }
      },
      resetPassword: {
        password: {
          required,
          alpha,
          minLength: minLength(8),
        },
        confirmPassword: {
          required,
          sameAsPassword: sameAs("password"),
        }
      },
      updateBalance: {
        id: {
          required,
        },
        amount: {
          required,
        },
        type: {
          required,
        },
        password: {
          required
        }
      },
      updateFreezeBalance: {
        id: {
          required,
        },
        amount: {
          required,
        },
        type: {
          required,
        },
        password: {
          required
        }
      }
    
      
    },
    middleware: "authentication",
    async mounted() {
      this.$root.$on('bv::dropdown::hide', bvEvent => {
        console.log('Dropdown is about to be hide', bvEvent)
        //after dismiss
        this.quickUpdate={
            all_value:"",
            payin_rate: "",
            payin_extra_charge: "",
            payout_rate: "",
            payout_extra_charge: "",
            settlement_rate: "",
            settlement_extra_charge: "",
            ewallet_rate: "",
            ewallet_extra_charge: "",
        }
      })
      this.reload();
      //   this.title = PageTitle
      //   this.items[1].text = PageTitle
      //   // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
      //   // this.$refs.main.changeVerticalTopBar("bill",true)
      //   // this.$refs.main.setShowFooterCert(false)
      //   // this.$refs.main.setPageTitle('title')
      //   console.log( this.$refs.commonFunc.getPhone())
      //   this.accessToken = this.$refs.commonFunc.getToken()
      //   this.accessUsername = this.$refs.commonFunc.getUsername()
    },
    created() {
    },
    methods: {
    getReseller(type) {
      if(cancel !== undefined) cancel();
      console.log("getReseller")
      this.$Progress.start();
      this.loading = true
      this.disabled = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("accessUsername", this.accessUsername);
      bodyFormData.append("page", "1");
      bodyFormData.append("limit", "999999");
      bodyFormData.append("resellerId", (this.account_type==='admin') ? "" : "all", );
      bodyFormData.append("searchReseller", "")
      axios({
        method: "post",
        url: appConfig.APIHostAdmin + 'controller/admin/getAllResellerList',
        data: bodyFormData,
        headers: {
          "Content-Type": "multipart/form-data"
        },
        cancelToken: new CancelToken(c => cancel = c)
      }).then((response) => {
        var resData = response.data;
        if (resData.status == 200) {
          this.reseller = []
          this.selectedReseller = ResellerDefault
          resData.data.forEach((element) => {
            if (element.account_username!==this.defaultName){
              this.reseller.push(element);
            }
          });
          this.selectedReseller = this.reseller[0]
          // if (type=='create'){
          //   resData.data.forEach((element) => {
          //   this.reseller.push(element);
          //   });
          //   this.selectedReseller = this.reseller[0]
          // }else if (type=='edit'){
          //   const parentId = this.data.updateData.reseller_parent[this.data.updateData.reseller_parent.length - 1].account_db_code;
          //   console.log(this.data.updateData.reseller_parent)
          //   resData.data.forEach((element) => {
          //     if (element.account_db_code == parentId){
          //       this.reseller.push(element);
          //     }
          //   });
          //   this.selectedReseller = this.reseller[0]
          // }
         

          //if login as reseller
          if (this.account_type=='reseller'){
            this.reseller=[resData.current_account]
            this.selectedReseller = this.reseller[0]
          }
          
          if(type=='create'){
            this.getData()
          }else if(type=='edit'){
            this.getEditData()
          }

        } else if (resData.status == 440) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          localStorage.clear();
          this.$router.push({
            path: "/login",
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            html: `${resData.message}.`,
            confirmButtonColor: '#222',
            confirmButtonText: this.$t('siteLang.Done'),
          })
          this.loading = false;
          this.disabled = false
        }
        this.$Progress.finish();
      }).catch((error) => {
        this.loading = false;
        this.disabled = false
        this.$Progress.finish();
        //Swal.fire("Error", error, "error");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          html: `${error}.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      });
    },
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getResellerDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            
             //clear data
             this.wallets=[];
             this.ownCost=[];
             this.contracts=[];
             this.uplineCost=[];
             this.formData.contract_selected=[]
             //
             var returnData = resData.data;
             var upline_contracts= []
             returnData.own_cost_list.forEach(element => {
              if (element.initial_contact_enable){
              upline_contracts.push(
                {
                  contract_name: element.contract_name,
                  contract_currency: element.contract_currency,
                  payin_rate: element.payin_rate,
                  payin_extra_charge: element.payin_extra_charges,
                  payout_rate: element.payout_rate,
                  payout_extra_charge: element.payout_extra_charges,
                  settlement_rate: element.settlement_rate,
                  settlement_extra_charge: element.settlement_extra_charges,
                  ewallet_rate: element.ewallet_rate,
                  ewallet_extra_charge: element.ewallet_extra_charges,
                  status: (element.contract_status  === 'available') ? 1 : 0,
                  upline_disable: element.upline_disable
                },
              )
              //follow parent upline contract and push fake
              this.formData.contract_selected.push(
                {
                  name: element.contract_name,
                  currency: element.contract_currency,
                  payin_rate: "0",
                  payin_extra_charge: "0",
                  payout_rate: "0",
                  payout_extra_charge: "0",
                  settlement_rate: "0",
                  settlement_extra_charge: "0",
                  ewallet_rate: "0",
                  ewallet_extra_charge: "0",
                  status:1,
                }
              );
              }
             });
             this.uplineCost = upline_contracts;
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getEditData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        bodyFormData.append("merchantId", this.data.updateData.account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getMerchantDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
             console.log(resData)
              //this.reload()
             var returnData = resData.data;
             var upline_contracts= []
             var contracts = []
             var wallets = []
             var downlineTotalCost = []
             returnData.reseller_contract.forEach(element => {
              upline_contracts.push(
                {
                  contract_name: element.contract_name,
                  contract_currency: element.contract_currency,
                  payin_rate: element.payin_rate,
                  payin_extra_charge: element.payin_extra_charges,
                  payout_rate: element.payout_rate,
                  payout_extra_charge: element.payout_extra_charges,
                  settlement_rate: element.settlement_rate,
                  settlement_extra_charge: element.settlement_extra_charges,
                  ewallet_rate: element.ewallet_rate,
                  ewallet_extra_charge:element.ewallet_extra_charges,
                  upline_disable:element.upline_disable,
                  status: (element.contract_status  === 'available') ? 1 : 0,
                }
              )
             });
             returnData.contract.forEach(element => {
              contracts.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,

                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });

             returnData.own_cost_list.forEach(element => {
              downlineTotalCost.push(
                {
                    contract_name: element.contract_name,
                    contract_currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charges,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charges,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charges,
                    ewallet_rate:  element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charges,
                    status: (element.contract_status  === 'available') ? 1 : 0,

                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total: element.contract_total,
                    contract_balance: element.contract_balance,
                 }
              )
             });
             
             
             returnData.wallets.forEach(element => {
              wallets.push(
                {
                  currency: element.contract_currency,
                  total_payin: element.total_payin,
                  total_ewallet: element.total_ewallet,
                  total_payout: element.total_payout,
                  total_settlement: element.total_settlement,
                  contract_total:element.contract_total,
                  contract_balance: element.contract_balance,
                },
              )
             });
             
             
             var dataReturn ={
                name: returnData.account_name,
                username: returnData.account_username,
                website_url:returnData.website_url,
                whitelist_ip:returnData.whitelist_ip,
                login_ip:returnData.login_ip,
                callback_url:returnData.callback_url,
                remarks: returnData.remarks || '',
                group: returnData.financial_group || '',
                status:returnData.status,
                status_flag: returnData.status_flag,
                login_block: returnData.login_block,
                api_enabled: returnData.api_enabled || 0,
                merchant_code: returnData.merchant_code,
                merchant_key: returnData.merchant_key,
                code_for_support_skype: returnData.code_for_support_skype || returnData.merchant_code,
                upline_contract: upline_contracts,
                contract:contracts,
                wallets: wallets,
                reseller_parent: returnData.reseller_parent,
                downlineTotalCost: downlineTotalCost
              }
             
              this.formEditData.name = dataReturn.name
              this.formEditData.websiteUrl = dataReturn.website_url
              this.formEditData.whitelistIP = dataReturn.whitelist_ip
              this.formEditData.whitelistLoginIP = dataReturn.login_ip
              this.formEditData.callBackUrl = dataReturn.callback_url
              this.formEditData.remarks = dataReturn.remarks
              this.formEditData.group = dataReturn.group
              this.formEditData.status = (dataReturn.status === 'active') ? 1 : 0
              this.formEditData.status_flag = dataReturn.status_flag
              this.formEditData.login_block = dataReturn.login_block
              this.formEditData.api_enabled = dataReturn.api_enabled
              this.updateData.username= dataReturn.username
              this.formEditData.merchant_code = dataReturn.merchant_code
              this.formEditData.merchant_key = dataReturn.merchant_key
              this.formEditData.code_for_support_skype = dataReturn.code_for_support_skype
              this.uplineCost= dataReturn.upline_contract
              this.uplineInfo= dataReturn.reseller_parent
              dataReturn.contract.forEach(element => {
                this.formEditData.contract_selected.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance
                  }
                );
                this.contracts.push(
                  {
                    name: element.contract_name,
                    currency: element.contract_currency,
                    payin_rate: element.payin_rate,
                    payin_extra_charge: element.payin_extra_charge,
                    payout_rate: element.payout_rate,
                    payout_extra_charge: element.payout_extra_charge,
                    settlement_rate: element.settlement_rate,
                    settlement_extra_charge: element.settlement_extra_charge,
                    ewallet_rate: element.ewallet_rate,
                    ewallet_extra_charge: element.ewallet_extra_charge,
                    status: element.status,
                    total_payin: element.total_payin,
                    total_payout: element.total_payout,
                    total_settlement: element.total_settlement,
                    contract_total:element.contract_total,
                    contract_balance: element.contract_balance
                  }
                )
              });
              this.wallets = dataReturn.wallets
              this.ownCost = downlineTotalCost


              //create payin
              this.createPayin.group = dataReturn.group;
              this.createPayin.merchant_code = returnData.merchant_code;
              this.createPayin.merchant_key = returnData.merchant_key;
              this.createPayin.callBackUrl = dataReturn.callback_url;
            
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    getGroups(){
        console.log("getGroups")
        this.$Progress.start();
        this.disabled = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", "1");
        bodyFormData.append("limit", "999999");
        axios({
          method: "post",
          url: appConfig.APIHostAdmin + 'controller/financialGroup/getAllFinancialGroupList',
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              this.financialGroup=[];
              resData.data.forEach(element => {
                if (element.status=='available'){
                  this.financialGroup.push({
                    id: element.id,
                    name: element.name,
                    account_db_code: element.account_db_code,
                    status: element.status,
                  });
                }
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.disabled = false
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.disabled = false
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
        });
    },
    switchVisibility() {
      (this.classicon =
        this.classicon === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password");
    },
    switchVisibility2() {
      (this.classicon2 =
        this.classicon2 === "uil uil-eye-slash"
          ? "uil uil-eye"
          : "uil uil-eye-slash"),
        (this.passwordFieldType2 =
          this.passwordFieldType2 === "password" ? "text" : "password");
    },
    updateAllInput(){
      if (this.quickUpdate.all_value!==''){
        this.quickUpdate={
            all_value:this.quickUpdate.all_value,
            payin_rate: this.quickUpdate.all_value,
            payin_extra_charge: this.quickUpdate.all_value,
            payout_rate: this.quickUpdate.all_value,
            payout_extra_charge: this.quickUpdate.all_value,
            settlement_rate: this.quickUpdate.all_value,
            settlement_extra_charge: this.quickUpdate.all_value,
            ewallet_rate: this.quickUpdate.all_value,
            ewallet_extra_charge: this.quickUpdate.all_value,
        }
      }
    },
    quickUpdateRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
            if (this.quickUpdate.payin_rate!==''){
              element.payin_rate = this.quickUpdate.payin_rate
            }
            if (this.quickUpdate.payin_extra_charge!==''){
              element.payin_extra_charge = this.quickUpdate.payin_extra_charge
            }
            if (this.quickUpdate.payout_rate!==''){
              element.payout_rate = this.quickUpdate.payout_rate
            }
            if (this.quickUpdate.payout_extra_charge!==''){
              element.payout_extra_charge = this.quickUpdate.payout_extra_charge
            }
            if (this.quickUpdate.settlement_rate!==''){
              element.settlement_rate = this.quickUpdate.settlement_rate
            }
            if (this.quickUpdate.settlement_extra_charge!==''){
              element.settlement_extra_charge = this.quickUpdate.settlement_extra_charge
            }
            if (this.quickUpdate.ewallet_rate!==''){
              element.ewallet_rate = this.quickUpdate.ewallet_rate
            }
            if (this.quickUpdate.ewallet_extra_charge!==''){
              element.ewallet_extra_charge = this.quickUpdate.ewallet_extra_charge
            }
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if  (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
      
    },
    resetRateAndExtraCharge(form){
      if (this[form].contract_selected.length){
        this[form].contract_selected.forEach(element => {
          element.payin_rate = 0
          element.payin_extra_charge = 0
          element.payout_rate = 0
          element.payout_extra_charge = 0
          element.settlement_rate = 0
          element.settlement_extra_charge = 0
          element.ewallet_rate = 0
          element.ewallet_extra_charge = 0
        });
      }
      if (form=='formData'){
        this.$refs.dropdown.hide(true)
      }else if  (form=='formEditData'){
        this.$refs.dropdown2.hide(true)
      }
    },
    inputNumberOnly(event, parentModel, childModel) {
      const numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
    },
    inputNumberOnlyV2(event, parentModel, childModel) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      this[parentModel][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel][childModel] = val;
      // }
    },
    inputNumberOnlyV3(event, parentModel, childModel, index) {
      var numericValue = event.target.value.replace(/[^0-9.]/g, '');
      //this[parentModel][childModel] = numericValue
      this[parentModel].contract_selected[index][childModel] = numericValue
      // if(numericValue.length > 2){
      //   var val = numericValue.replace(/[^0-9]/g, '');
      //   console.log(val);
      //   val = val.substr(0, val.length-2)+"."+val.substr(-2);
      //   this[parentModel].contract_selected[index][childModel] = val;
      // }
    },
    convertCurrencyFormat(value, show00) {
      if (show00 == true) {
        return this.$refs.commonFunc.convertCurrencyFormat(value)
      } else {
        // Assuming this.transactionLimit is defined in your component's data
        return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00", "");
      }
    },
    showModal() {
      this.$refs['modal-merchant'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData;
      if (this.account_type == "admin"){
        this.getGroups();
      }
      this.getReseller('create');
    },
    showEditModal() {
      this.$refs['modal-merchant-edit'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.formEditData.id = this.data.updateData.account_db_code

      this.resetPassword =  {
        id:this.data.updateData.account_db_code,
        password: "",
        confirmPassword: ""
      }
      if (this.account_type == "admin"){
        this.getGroups();
      }
      this.getReseller('edit');
    },
    showMerchantCreatePayinModal() {
      this.$refs['modal-merchant-create-payin'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.createPayin.id = this.data.updateData.account_db_code;
      this.createPayin.type = "UPI";
      //this.getGroups();
      //this.getReseller('edit');
      this.getEditData()
    },
    showMerchantUpdateBalanceModal() {
      this.$refs['modal-merchant-update-balance'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.updateBalance.id = this.data.updateData.account_db_code;
      this.updateBalance.type = "+";
      //this.getGroups();
      this.getEditData();
    },
    showMerchantUpdateFreezeBalanceModal() {
      this.$refs['modal-merchant-update-freeze-balance'].show()
      this.accessToken = this.data.accessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.updateFreezeBalance.id = this.data.updateData.account_db_code;
      this.updateFreezeBalance.type = "+";
      //this.getGroups();
      this.getEditData();
    },
    detectOperator(event) {
      if (event.target.value.includes('+')) {
        this.updateBalance.type = '+'
      } else if (event.target.value.includes('-')) {
        this.updateBalance.type = '-'
      } 
    },
    showResetPasswordModal() {
      this.$refs['modal-reset-password'].show()
    },
    showViewModal(type) {
      if (type =='contract'){
        this.$refs['modal-merchant-contract'].show()
      }else if (type =='wallet'){
        this.$refs['modal-merchant-wallet'].show()
      }
      this.accessToken = this.data.assessToken;
      this.accessUsername = this.data.accessUsername;
      this.updateData = this.data.updateData
      this.getEditData();
    },
    resetModal() {
      this.disabled = false;
      this.modalSubmit = false;
      this.modalLoading = false;
      this.financialGroup=[];
      this.wallets=[];
      this.ownCost=[];
      this.contracts=[];
      this.uplineCost=[];
      this.formData={
        username:"",
        password: "",
        confirmPassword: "",
        name:"",
        websiteUrl:"",
        whitelistIP:"",
        callBackUrl:"",
        remarks:"",
        group:"",
        contract_selected:[],
      }
      this.formEditData = {
        id:"",
        merchant_code:"",
        merchant_key:"",
        code_for_support_skype:"",
        name:"",
        status:"",
        status_flag:"",
        login_block:"",
        api_enabled:"",
        websiteUrl:"",
        whitelistIP:"",
        whitelistLoginIP:"",
        callBackUrl:"",
        remarks:"",
        group:"",
        contract_selected:[],
      }
      this.resetPassword= {
        id:"",
        password: "",
        confirmPassword: ""
      }

      this.createPayin= {
        id:"",
        amount:"",
        type:"UPI",
        group:"",
        merchant_code:"",
        merchant_key:"",
        callBackUrl:"",
      }
      this.copyMessages=""

      this.updateBalance= {
        id:"",
        amount:"",
        type:"+",
        remarks:"",
        password:""
      }

      this.updateFreezeBalance= {
        id:"",
        amount:"",
        type:"+",
        remarks:"",
        password:""
      }
      
    },
    resetModal2() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.resetPassword.password= ""
      this.resetPassword.confirmPassword= ""
    },
    resetPasswordRequest(){
      this.modalSubmit = true
      this.$v.resetPassword.$touch();
      console.log((this.$v.resetPassword.$invalid))

      if (this.$v.resetPassword.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("accountDbCode", this.resetPassword.id);
        bodyFormData.append("password", this.resetPassword.password);
        axios({
            method: "post",
            url:  appConfig.APIHostAdmin + 'controller/admin/resetResellerMerchantPassword',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Password Reset Success',
                html: 'Your password for this account has been successfully reset!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-reset-password'].hide()
                this.$refs['modal-merchant-edit'].hide()
                this.$emit('callParentFunction2');
              })
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    addMerchantRequest(){
      this.modalSubmit = true
      this.$v.formData.$touch();
      console.log((this.$v.formData.$invalid))
      var NoContactEnabled = this.formData.contract_selected.map((item) => parseInt(item.status)).includes(1);

      if (this.$v.formData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      }
       else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData = []
        this.formData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("name", this.formData.name);
        bodyFormData.append("username", this.formData.username);
        bodyFormData.append("password", this.formData.password);
        bodyFormData.append("resellerId", this.selectedReseller.account_db_code);
        bodyFormData.append("websiteUrl", this.formData.websiteUrl);
        bodyFormData.append("remarks", this.formData.remarks);
        bodyFormData.append("financialGroup", JSON.stringify(this.formData.group));
        bodyFormData.append("whitelistIP", this.formData.whitelistIP);
        bodyFormData.append("callbackUrl", this.formData.callBackUrl);
        bodyFormData.append("contracts", JSON.stringify(contractData));
        axios({
            method: "post",
            //url:  appConfig.DemoAPI,
            url: appConfig.APIHostAdmin + 'controller/admin/addMerchant',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.copyMessages = `Welcome to ${appConfig.title}!\nYour merchant account is ready!\n\nURL: https://portal.zpay.world\n\nUsername: ${this.formData.username}\nPassword: ${this.formData.password}\n\n** Please go to "Technical" from the Menu to get the Integration API documents and the contacts of our integration team.`
              // Swal.fire({
              //   icon: 'success',
              //   title: `Welcome to ${appConfig.title}!`,
              //   //html: 'Your merchant account has been successfully created!',
              //   html: `
              //   <p>Your merchant account is ready!</p>
              //   <p>URL: <a href="https://portal.zpay.world" target="_blank">https://portal.zpay.world</a></p>
              //   <p>
              //     Username: ${this.formData.username}<br>
              //     Password: ${this.formData.password}
              //   </p>
              //   <p class="font-size-12">** Please go to "Technical"  from the Menu to get the Integration API documents and the contacts of our integration team.</p>`,
              //   confirmButtonColor: '#FA6541',
              //   showCancelButton: true,
              //   cancelButtonText: "Cancel",
              //   confirmButtonText: `<i class="bi bi-copy"></i> Copy Message`,
              // }).then((result) => {
              //   console.log(result);
              //   if (result.isConfirmed) {
              //     Swal.fire("Message Copied!", "You can paste and send to your merchant.", "success");
              //     const msg = this.copyMessages;
              //     navigator.clipboard.writeText(msg)
              //   }
              //   this.copyMessages=""
              //   this.$refs['modal-merchant'].hide()
              //   this.$emit('callParentFunction', this.selectedReseller.account_db_code);
              // })
              Swal.fire({
                icon: 'success',
                title: 'Merchant Account Created',
                html: 'Your merchant account has been successfully created!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-merchant'].hide()
                this.$emit('callParentFunction');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateMerchantRequest(){
      this.modalSubmit = true
      this.$v.formEditData.$touch();
      console.log((this.$v.formEditData.$invalid))
      var NoContactEnabled = this.formEditData.contract_selected.map((item) => parseInt(item.status)).includes(1);

      if (this.$v.formEditData.$invalid) {
        return;
      }
      else if (!NoContactEnabled) {
        Swal.fire(
          {
          icon: 'error',
          title: 'Oops...',
          html: `Please enable at least one contract.`,
          confirmButtonColor: '#222',
          confirmButtonText: this.$t('siteLang.Done'),
        })
      }else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        var contractData =[]
        this.formEditData.contract_selected.forEach(element => {
          contractData.push({
            name: element.name,
            currency: element.currency,
            payin_rate:element.payin_rate,
            payin_extra_charges: element.payin_extra_charge,
            payout_rate: element.payout_rate,
            payout_extra_charges: element.payout_extra_charge,
            settlement_rate:element.settlement_rate,
            settlement_extra_charges: element.settlement_extra_charge,
            ewallet_rate: element.ewallet_rate,
            ewallet_extra_charges: element.ewallet_extra_charge,
            status: element.status
          })
        });
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("name", this.formEditData.name);
        bodyFormData.append("merchantId", this.formEditData.id);
        bodyFormData.append("websiteUrl", this.formEditData.websiteUrl);
        bodyFormData.append("remarks", this.formEditData.remarks);
        bodyFormData.append("financialGroup", JSON.stringify(this.formEditData.group));
        bodyFormData.append("whitelistIP", this.formEditData.whitelistIP);
        bodyFormData.append("loginIP", this.formEditData.whitelistLoginIP);
        bodyFormData.append("callbackUrl", this.formEditData.callBackUrl);
        bodyFormData.append("status", this.formEditData.status);
        bodyFormData.append("loginBlock", this.formEditData.login_block);
        bodyFormData.append("apiEnabled", this.formEditData.api_enabled);
        bodyFormData.append("contracts", JSON.stringify(contractData));

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchant',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'Merchant Account Updated',
                html: 'Your merchant account has been successfully updated!',
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-merchant-edit'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    createMerchantPayin(){
      this.modalSubmit = true
      this.$v.createPayin.$touch();

      if (this.$v.createPayin.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("merchantId", this.createPayin.id);
        bodyFormData.append("amount", this.createPayin.amount);
        bodyFormData.append("type", this.createPayin.type);
        bodyFormData.append("callbackUrl", this.createPayin.callBackUrl);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/generatePayin',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
             
              const url = resData.redirect_url

              this.copyMessages = url
              

              if (this.account_type=='merchant'){
                //for merchant view
                Swal.fire({
                  icon: 'success',
                  title: 'New Payin Created',
                  html: `Payment link has been successfully created! `,
                  confirmButtonColor: '#FA6541',
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  confirmButtonText: `<i class="bi bi-globe-asia-australia"></i> Go to the Payment Link`,
                }).then((result) => {
                  this.$emit('callParentFunction2');
                  if (result.isConfirmed) {
                    const a = document.createElement('a');
                    a.href = url
                    a.target = '_blank'; // To open the link in a new tab
                    document.body.appendChild(a); // Append the anchor to the body
                    a.click(); // Trigger the click event
                    document.body.removeChild(a); // Remove the anchor from the DOM
                  }
                  this.copyMessages=""
                  this.$refs['modal-merchant-create-payin'].hide()
                })
              }else{
                //for admin view
                Swal.fire({
                  icon: 'success',
                  title: 'New Payin Created',
                  html: `Payment link has been successfully created! `,
                  confirmButtonColor: '#FA6541',
                  showCancelButton: true,
                  cancelButtonText: "Cancel",
                  confirmButtonText: `<i class="bi bi-copy"></i> Copy Payment Link`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    Swal.fire("Payment Link Copied!", "You can paste and send to your merchant.", "success");
                    const msg = this.copyMessages;
                    navigator.clipboard.writeText(msg)
                  }
                  this.copyMessages=""
                  this.$refs['modal-merchant-create-payin'].hide()
                  this.$emit('callParentFunction2');
                })
              }
             
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateWalletBalance(){
      this.modalSubmit = true
      this.$v.updateBalance.$touch();

      if (this.$v.updateBalance.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("merchantId", this.updateBalance.id);
        bodyFormData.append("amount", this.updateBalance.amount);
        bodyFormData.append("type", this.updateBalance.type);
        bodyFormData.append("remarks", this.updateBalance.remarks);
        bodyFormData.append("password", this.updateBalance.password);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchantBalance',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              Swal.fire({
                icon: 'success',
                title: 'Balance Updated!',
                html: `${this.updateData.account_name} 's balance has been successfully updated.`,
                confirmButtonColor: '#FA6541',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: `Done`,
              }).then(() => {

                this.$refs['modal-merchant-update-balance'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    updateFreezeWalletBalance(){
      this.modalSubmit = true
      this.$v.updateFreezeBalance.$touch();
      if (this.$v.updateFreezeBalance.$invalid) {
        return;
      }
      else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername", this.accessUsername);
        bodyFormData.append("merchantId", this.updateFreezeBalance.id);
        bodyFormData.append("amount", this.updateFreezeBalance.amount);
        bodyFormData.append("type", this.updateFreezeBalance.type);
        bodyFormData.append("remarks", this.updateFreezeBalance.remarks);
        bodyFormData.append("password", this.updateFreezeBalance.password);

        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/updateMerchantFrozenBalance',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              
              Swal.fire({
                icon: 'success',
                title: 'Frozen Balance Update!',
                html: `${this.updateData.account_name} 's frozen balance has been successfully updated.`,
                confirmButtonColor: '#FA6541',
                showCancelButton: true,
                cancelButtonText: "Cancel",
                confirmButtonText: `Done`,
              }).then(() => {
                this.$refs['modal-merchant-update-freeze-balance'].hide()
                this.$emit('callParentFunction2');
              })
              
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted
        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
    }
  };
</script>
<style scoped>
  #eye_current,
  #eye,
  #eye2,
  #eye3{
  position: absolute;
  top: 4px;
  padding: 5px 10px;
  right: 5px;
  text-align: center;
  width: 32px;
  color: #999;
  z-index: 3;
  background-color: #fff;
  cursor: pointer;
  }
  .custom-table td .row{
    min-width:220px;
    
  }
</style>